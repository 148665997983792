import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';
import {Navbar,Nav,NavDropdown,Form,FormControl,Modal,Button} from 'react-bootstrap';
import PropTypes from "prop-types";
import { useState } from 'react';

import SimpleChatBot from '../chatbot/SimpleForm.js';
import SocialFollowUp from '../SocialFollowUp';
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../googleutils/refreshToken';
import AuthenticateService from "../service/AuthenticateService";
import AuthenticationService from "../service/AuthenticateService";

import "../css/login.css"

import Software from "../images/Software.jpeg";
import ShipYard from "../images/ShipYard.jpeg";
import Pharma from "../images/Pharma.jpeg";
import Flights from "../images/Flights.jpeg";
import Hospitals from "../images/Hospitals.jpeg";
import Construction from "../images/Construction.jpeg";
import Manufacturing from "../images/Manufacturing.jpeg";
import Universities from "../images/Universities.jpeg";
import Tourism from "../images/Tourism.jpeg";
const gClientId = '989064674711-ihqe4b7sbvl8u3lu4f8jp68hvuo9hget.apps.googleusercontent.com';

export default class Login extends Component {

  constructor(props){
        super(props);
       
        this.loginClicked=this.loginClicked.bind(this);
         this.state={
            username:'',
            password:'',
            hasLoginFail:false,
            showSuccessMessage:false,
            modalshow: false,
            login_hide_show:false,
            dynamicheight:0,
            dynamicwidth:0,
            bookalbumdisplayflag:true,
            time: new Date() 
        }
        this.handleChange=this.handleChange.bind(this);
        this.loginClicked=this.loginClicked.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        //this.handlgeGoogleSuccessSignIn = this.handlgeGoogleSuccessSignIn.bind();
        this.assigncustomername=this.assigncustomername.bind(this);
        this.assigncustomercredentials=this.assigncustomercredentials.bind(this);
        this.onSuccess=this.onSuccess.bind(this);
        this.loginbuttonhide = this.loginbuttonhide.bind(this);
        this.loginbuttonshow=this.loginbuttonshow.bind(this);
        this.closeBookAlbum=this.closeBookAlbum.bind(this);
        
    }

    componentDidMount = (props) => {
          document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        AuthenticateService.ClearFlagsforLogin();
        
        this.update = setInterval(() => {
            this.setState({ time: new Date() });
        }, 1 * 1000); // every 1 seconds
        
          
          this.displayBanner = setInterval(() => {
            document.querySelector(".galleryName").style.display="block";
            
        },1 * 1000)
      

        };

     componentWillUnmount() {
       // clearInterval(this.timer);
        clearInterval(this.update);
         clearInterval(this.displayBanner);
      }
       closeBookAlbum(){
                this.setState({bookalbumdisplayflag : false});
        }
        loginbuttonhide(){
            this.setState({login_hide_show : false});
        }

        loginbuttonshow(){
            this.setState({login_hide_show : true});
        }

       onSuccess = (res) => {
          

           //console.log('Login Success: currentUser:', res.profileObj);
            //alert(`Logged in successfully welcome ${res.profileObj.name} 😍. \n `);
        
           // refreshTokenSetup(res);
            this.handlgeGoogleSuccessSignIn(res);
       };
       onFailure = (res) => {
        //console.log('Login failed: res:', res);
        //alert(`Failed to login. 😢` );
        
      };

        handleChange(event){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    [event.target.name]
                        : event.target.value
                }
            )
        }

        assigncustomername(value){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    username : value }
            )
        }
            assigncustomercredentials(value){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    password : value
                }
            )
        }
            

        handlgeGoogleSuccessSignIn(res){
            //console.log("login success");
            AuthenticationService.registerSuccessfulLoginThirdPartyForJwt(this.state.username, "45678987654312345afasfmad","google");
            
            this.setState({ showSuccessMessage: true });
            this.setState({ hasLoginFailed: false });
            //console.log("loginjs props::"+this.props.data);
                AuthenticateService.saveUser( this.formatUserobject(res.profileObj) );
            this.props.updateGoogleUserdata(res.profileObj);
            //this.props.history.push(`/dashboard/home`);  commented temporarly to display html in dashboard
                this.props.history.push(`/Dashboard`); 
            //this.props.history.push("/display")
        }

        formatUserobject(resProfObj){
            const saveUserOobj={
                            googleUserMailId: resProfObj.email,
                            googleuserFistName: resProfObj.name,
                            googleUserLastName: resProfObj.givenName,
                            googleUserImage: resProfObj.imageUrl 

            }
        return saveUserOobj;
        }

        showModal = () => {
        this.setState({ modalshow: true });
        };

        hideModal = () => {
        this.setState({ modalshow: false });
        };

        loginClicked(event){
        event.preventDefault();
        //console.log("in login clicked");
        //debugger;
        const usrname=this.state.username;
        const pwd=this.state.password;
        const myHeaders = new Headers();
        const auth = "Basic "+btoa(this.state.username+":"+this.state.password);
        myHeaders.append("Authorization", auth);
        const raw = "";
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };



        if( AuthenticationService
            .executestaticpassword(this.state.username, this.state.password)){
                //console.log("login success");
            AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, "45678987654312345afasfmad");
            this.setState({ showSuccessMessage: true });
            this.setState({ hasLoginFailed: false });
            const userobj = {    
                                googleUserImage: 'testImage', 
                                googleUserMailId: 'user@nirupanaidu.com', 
                                googleuserFistName: 'userfirstname', 
                                googleUserLastName: 'userlastname'
                                }
            AuthenticateService.saveUser(userobj);
            this.props.updateGoogleUserdata(userobj);
            //this.props.history.push(`/dashboard/home`);  commented temporarly to display html in dashboard
            this.props.history.push(`/Dashboard`); 
            //this.props.history.push("/display");
            }

        }
    


    render() {
            const  loginformClassName = this.state.login_hide_show ? "display-block" : "display-none";
        return (
                <>
                
                    <style>{`
                    .line{
                        background-color:white; 
                        padding-left:20px; 
                        padding-right:20px; 
                        max-width:550px; 
                        margin:auto; 
                        border-radius:5px;
                        padding-bottom:5px; 
                        text-align:left; 
                        margin-bottom:40px;
                        width:35%;
                    }
                    .center {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    ul{
                        display: flex;
                        justify-content: center;
                    }
                   ul > li{
                       
                        display:inline-block;
                        padding: 0px 40px;
                        first-child{padding-left:0;}
                        last-child{padding-right:0;}
                    }
                    h1 {
                        text-shadow: 0 1px 0 #ccc,
                                    0 2px 0 #c9c9c9,
                                    0 3px 0 #bbb,
                                    0 4px 0 #b9b9b9,
                                    0 5px 0 #aaa,
                                    0 6px 1px rgba(0,0,0,.1),
                                    0 0 5px rgba(0,0,0,.1),
                                    0 1px 3px rgba(0,0,0,.3),
                                    0 3px 5px rgba(0,0,0,.2),
                                    0 5px 10px rgba(0,0,0,.25),
                                    0 10px 10px rgba(0,0,0,.2),
                                    0 20px 20px rgba(0,0,0,.15);
                                    
                        color: gold;
                        text-transform: uppercase;;
                        position: absolute;
                        opacity: 0;
                        top: 4vh;
                        left: 20vw;
                        animation: cinematicInOut 5s ease-in-out 2s;
                        }

                        h1:nth-child(2) {
                        animation-delay: 8s;
                        }

                        h1:nth-child(3) {
                        animation-delay: 11s;
                        }
                        h1:nth-child(4) {
                        animation-delay: 14s;
                        }
                        h1:nth-child(5) {
                        animation-delay: 17s;
                        }
                        h1:nth-child(6) {
                        animation-delay: 20s;
                        }
                        h1:nth-child(7) {
                        animation-delay: 23s;
                        }
                        h1:nth-child(8) {
                        animation-delay: 26s;
                        }
                        h1:nth-child(9) {
                        animation-delay: 29s;
                        }
                        h1:nth-child(10) {
                        animation-delay: 32s;
                        }
                        h1:nth-child(11) {
                        animation-delay: 38s;
                        }
                        h1:nth-child(12) {
                        animation-delay: 42s;
                        }
                        h1:nth-child(13) {
                        animation-delay: 45s;
                        }
                        h1:nth-child(14) {
                        animation-delay: 48s;
                        }
                        h1:nth-child(15) {
                        animation-delay: 51s;
                        }
                         h1:nth-child(16) {
                        animation-delay: 54s;
                        }
                        @keyframes cinematicInOut {
                        0% {
                            text-shadow: 0 0 1em white;
                            opacity: 0;
                            margin-left: -0.5em;
                        }
                        25% {
                            text-shadow: 0 0 0 white;
                            opacity: 1;
                            margin-left: 0;
                        }
                        75% {
                            text-shadow: 0 0 0 white;
                            opacity: 1;
                            margin-left: 0;
                        }
                        100% {
                            text-shadow: 0 0 1em white;
                            opacity: 0;
                            margin-left: 0;
                        }
                        }

                        #offset {
                        animation: letter-flicker 2s linear infinite;
                        }
                        @keyframes letter-flicker {
                        0% {
                            opacity:0.1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        2% {
                            opacity:0.1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        4% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        
                        
                        19% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        21% {
                            opacity:0.1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        23% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        
                        80% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        83% {
                            opacity:0.4;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        87% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        }


                    h2 {
                        text-transform: uppercase;
                        font: 700 42px/1 "Arvo", sans-serif;
                        text-align: center;
                        -webkit-animation: in 10s ease-in-out forwards infinite;
                                animation: in 10s ease-in-out forwards infinite;
                        -webkit-animation-delay: 1s;
                                animation-delay: 1s;
                        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
                    }

                    @-webkit-keyframes in {
                        0% {
                            letter-spacing: 20px;
                        }
                        100% {
                            letter-spacing: 5px;
                        }
                    }

                    @keyframes in {
                        0% {
                            letter-spacing: 20px;
                        }
                        100% {
                            letter-spacing: 5px;
                        }
                    }

                    .watername {
                            position: absolute;
                            top: 1%;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            
                        
                            z-index: 8888;
                        

                    }
                    .galleryName {
                            position: absolute;
                            top: 1%;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            
                        
                            z-index: 8888;
                        

                    }
                    
                    .whiteflower {
                        position: relative;
                         top: 10%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        
                        z-index: 777;


                    }

                `}</style>


             <section >
                    
                    <Carousel variant="outline-danger" autoPlay interval="55000"  indicators={true} fade={true}>
                        <Carousel.Item  >				  
                            <section >    
                                         
                                <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginclass">                                                               
                                        <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                                       <div>
             <div className={loginformClassName}>
           
                         <div className="bkimage-content">
                        
                            <div className="auth-inner ">
                                   <div className="form-group ">
                                        
                                           <label >
                                              <GoogleLogin
                                                  clientId={gClientId}
                                                  buttonText="Log in with Google"
                                                  onSuccess={this.onSuccess}
                                                  onFailure={this.onFailure}
                                                  cookiePolicy={'single_host_origin'}
                                                  style={{ marginTop: '100px' }}
                                                  isSignedIn={false}
                                                />
                                          </label>
                                            
                                   </div>
                                   <h3 >OR</h3>
                                  <form onSubmit={this.loginClicked}>
                                        <h3 className="textSignIn">Sign In</h3>

                                        <div className="form-group">
                                            <label>User Name</label>
                                            <input autoFocus type="text" name="username"   value={this.state.username} onChange={this.handleChange}  className="form-control" placeholder="Enter user Name"  />
                                        </div>

                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" name="password" value={this.state.password} onChange={this.handleChange} className="form-control" placeholder="Enter password" />
                                        </div>

                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block" >Submit</button>
                                        <p className="forgot-password text-right">
                                            Forgot <a href="#">password?</a>
                                        </p>
                                  </form>
                                 
                                           
                                      <button type="submit" className="btn btn-primary btn-block"  
                                     onClick={this.loginbuttonhide.bind(this)} >  
                                    <b className="closeloginformbutton" id="offset">close </b></button>  
                                        
                            
                            </div>
                    </div>
                </div>
                 
        </div>           
                                </Navbar>                        
                            </section>                          
                            <Carousel.Caption> 
                             <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                              <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>                                   
                            </Carousel.Caption>
                                    
                        </Carousel.Item>
                                                
                        
                    </Carousel>
                   
                </section> 

             {/* <section id="galaxyIndex1">
                            <div className="welcometext" style={{color:"gold"}}> 
                                    <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} 
                                    <b>&nbsp;{this.state.time.toLocaleTimeString()} </b></p>
                                </div>
                          <h1 >
                        <span style={{fontSize:'50px'}}><br/> Welcome To  World #1 <p style={{color:'red'}}>KUPPALAS ENTERPRISES</p>  <br/><br/>
                           
                            </span>
                    </h1>
                    <h1><span style={{fontSize:'50px'}}><br/>Above 500,000+ Franchises<br/><br/>
                        
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'50px'}}><br/>Above 100,000,000+ Customers World Wide<br/><br/>
                           
                            </span>
                    </h1>
                    <h1><span style={{fontSize:'50px'}}><br/>#1 Brand in the World <br/><br/>
                           
                            </span>
                    </h1>
                     <h1><span style={{fontSize:'50px'}}><br/>ALL TIME BEST ENTERPRISE AWARD WINNER <br/><br/>
                            
                            </span>
                    </h1>
                    <h1><span style={{fontSize:'50px'}}><br/>We are at almost in all business<br/><br/>
                           
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'50px'}}><br/>Software<br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'35%'}} src={Software} width='300' height='300' alt='Girl'></img> 
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'50px'}}><br/>PHARMA<br/><br/>
                        <img className='whiteflower' id='flower6' style={{left:'35%'}} src={Pharma} width='250' height='250' alt='Girl'></img> 
                        </span>
                    </h1>
                     <h1><span style={{fontSize:'50px'}}><br/>Medical<br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'35%'}} src={Hospitals} width='250' height='250' alt='Girl'></img> 
                        </span>
                    </h1>
                    
                    <h1><span style={{fontSize:'50px'}}><br/>Flights <br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'25%'}} src={Flights} width='300' height='300' alt='Girl'></img> 
                        </span>
                    </h1> 
                    <h1><span style={{fontSize:'50px'}}><br/>SHIP YARDS <br/><br/>
                             <img className='whiteflower' id='flower6' style={{left:'25%'}} src={ShipYard} width='300' height='300' alt='Girl'></img> 
                        </span>
                    </h1> 
                      <h1><span style={{fontSize:'50px'}}><br/>Construction  <br/><br/>
                             <img className='whiteflower' id='flower6' style={{left:'15%'}} src={Construction} width='300' height='300' alt='Girl'></img> 
                        </span>
                    </h1> 
                     <h1><span style={{fontSize:'50px'}}><br/>Manufacturing  <br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'25%'}} src={Manufacturing} width='300' height='300' alt='Girl'></img> 
                        </span>
                    </h1>
                     <h1><span style={{fontSize:'50px'}}><br/>Tourism  <br/><br/>
                             <img className='whiteflower' id='flower6' style={{left:'25%'}} src={Tourism} width='300' height='300' alt='Girl'></img> 
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'50px'}}><br/>Universities  <br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'25%'}} src={Universities} width='300' height='300' alt='Girl'></img> 
                        </span>
                    </h1>

                    
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className='galleryName' id='gallery' style={{display:'none'}}>
                        <h2 > 
                            <span style={{fontSize:'70px',color:'red'}}> <br/>KUPPALAS   
                            
                            ENTERPRISES </span> 
                            <br/><span style={{fontSize:'50px'}}>&#128522;&#128512;&#128512;&#128514;&#128523;
                            <br/>
                            
                            </span>
                            <br/>
                    
                        </h2>
                    </div>
                  
                   
               </section> */}
               
                <section>
                <div className='galleryName' id='gallery' style={{display:'none'}}>
                        <h2 > 
                            <span style={{fontSize:'70px',color:'red'}}> <br/>KUPPALAS   
                            
                            ENTERPRISES </span> 
                            <br/><span style={{fontSize:'50px'}}>&#128522;&#128512;&#128512;&#128514;&#128523;
                            <br/>
                            
                            </span>
                            <br/>
                    
                        </h2>
                    </div>
                  <SimpleChatBot />  
                 
                </section>
                <h1 style={{ padding: '1em'}}></h1>
                <section className="fixed-bottom">
                    <SocialFollowUp/>
                </section>
                    
            </>
        );
    }
}

