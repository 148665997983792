import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import Post from '../chatbot/Post';
// all available config props
const config ={
  width: "400px",
  height: "500px",
  floating: true,
  headerTitle:'Ask Angel Sister',
  cache:false
  
};

class SimpleForm extends Component {
  render() {
    return (
      <ChatBot 
         steps={[
               {
            id:'q-Welcome', 
            message:'Welcome to KUPPALAS ENTERPRISES! Our AI device recognization program message is "you looks  dirty"  have a good bath first and reach us for help later. Still want to continue -> type the message .', 
            trigger:'Welcome',
          },
          {
            id:'Welcome', 
            user:true,
            trigger:'q-firstname'
          },
          {
            id:'q-firstname', 
            message:'What is your first name?', 
            trigger:'firstname',
          },
          {
            id:'firstname', 
            user:true,
            trigger:'q-lastname'
          },
          {
            id:'q-lastname', 
            message:'What is your last name?', 
            trigger:'lastname',
          },
          {
            id:'lastname', 
            user:true,
            trigger:'q-email'
          },
          {
            id:'q-email', 
            message:'Finally. what is your address?', 
            trigger:'email',
          },
          {
            id:'email', 
            user:true,
            trigger:'q-address-criticize'
          },
          {
            id:'q-address-criticize', 
            message:'use google search to reach your above address and have good rest. Please use common sense instead of depending on bot! Have a pleasent Day! Good Bye!', 
            trigger:'address-criticize',
          },
          {
            id:'address-criticize', 
            user:true,
            trigger:'q-submit'
          },
          {
            id:'q-submit', 
            message:'Do you wish to submit?', 
            trigger:'submit'
          },
          {
            id:'submit', 
            options:[
            {value:'y', label:'Yes', trigger:'end-message'},
            {value:'n', label:'No', trigger:'no-submit'},
            ] 
          },
          {
                  id: 'no-submit',
                  message:'Your information was not submitted.', 
                  end: true,
               },
                    {
                  id: 'end-message',
                  component: <Post />,
                  asMessage: true,
                  end: true,
               },
        ]}
        {...config}
        
      />
        
        );
      }

    }

    export default SimpleForm;