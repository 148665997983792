import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

import React, { Component } from "react";

import "./css/socialfollowup.css";
export default class SocialFollowUp extends Component {

      render() {
        return (
          <>
             <b className="textcopyright">Copyright &copy;</b> <label  className="textcopyright"> www.kuppalas.com   </label>
            <div className="social-container " >
              <b>Follow Us on</b>
              <a href="#"
                className="youtube social" onClick={ (e)=>e.preventDefault()}  >
                <FontAwesomeIcon icon={faYoutube} size="2x"  />
              </a>
        
                <a href="#"
                className="facebook social" onClick={(e)=>{e.preventDefault();}}>
                <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a href="#" className="twitter social" onClick={(e)=>{e.preventDefault();}}>
                <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
                <a href="#"
                className="instagram social" onClick={(e)=>{e.preventDefault();}}>
                <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                 
        </div>
      </>
        )
    }
}