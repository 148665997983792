import React, { Component } from 'react';
import {HashRouter as Router,BrowserRouter ,Route,Switch} from 'react-router-dom';
import NewYear2023 from './NewYear2023.js';
import ComapnyHisotry from './ComapnyHisotry.js';
import Login from './login/Login.js';



class RoutesHome extends Component {
    constructor(props){
        super(props);
        
    }
    
     updateGoogleUserdata (profileObj){
     
        this.setState({ googleUserMailId: profileObj.email });
        this.setState({ googleuserFistName: profileObj.name });
        this.setState({ googleUserLastName: profileObj.givenName });
        this.setState({ googleUserImage: profileObj.imageUrl });
       
    }
    render() {
        
        return (       
            
            <Switch>
             <Route exact path="/" 
                        render={
                            props => <NewYear2023 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/ComapnyHisotry" 
                        render={
                            props => <ComapnyHisotry {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/Login" 
                        render={
                            props => <Login {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                                        
            </Switch>
                                                   
        )
    }
}

export default RoutesHome;