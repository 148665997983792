import logo from './logo.svg';
import './App.css';
import ContextMenu from './ContextMenu';
import RoutesHome from './RoutesHome';
function App() {
  return (
    <>
       <ContextMenu/>
       <RoutesHome/>

    </>
  );
}

export default App;
